import React from 'react';
const Line = ({ width = '32', className = '', height = '32', viewBox='0 0 32 32' }) =>
  <svg
    width={width}
    height={height}
    viewBox={viewBox}
    className={className ? className : "svg-icon"}
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <path fill="#ef633e" fillRule="evenodd" d="M15.57 4c6.925 0 12.551 4.58 12.551 10.221 0 2.26-.858 4.28-2.673 6.281-2.634 3.04-8.54 6.76-9.877 7.321-1.33.549-1.165-.323-1.102-.66l.004-.02c.04-.2.18-1.08.18-1.08.04-.32.08-.82-.04-1.14-.14-.34-.678-.54-1.097-.62C7.47 23.503 3 19.282 3 14.22 3 8.581 8.647 4 15.57 4zM8.49 16.1v-4.361c0-.14-.1-.24-.24-.24h-.878c-.14 0-.24.1-.24.24v5.48c0 .06.02.12.06.16.04.04.1.06.16.06h3.512c.14 0 .24-.1.24-.24v-.88c.04-.1-.08-.22-.22-.22H8.49zm4.524-4.6h-.878c-.14 0-.24.1-.24.24v5.48c0 .14.1.24.24.24h.878c.14 0 .24-.1.24-.24v-5.48c0-.14-.1-.24-.24-.24zm6.068 0h-.878c-.14 0-.24.1-.24.24V15l-2.514-3.4-.02-.02s0-.02-.02-.02l-.02-.02h-1.037c-.14 0-.24.1-.24.24v5.48c0 .14.1.24.24.24h.878c.14 0 .24-.1.24-.24v-3.28l2.513 3.4.06.06h1.018c.14 0 .24-.1.24-.24v-5.46c.02-.14-.08-.24-.22-.24zm4.87 1.382c.14 0 .24-.1.24-.24v-.88c0-.14-.1-.24-.24-.24H20.42c-.06 0-.12.02-.18.06-.04.04-.06.1-.06.16v5.48c0 .06.02.12.06.16.04.04.1.06.16.06h3.552c.14 0 .24-.1.24-.24v-.88c0-.14-.1-.24-.24-.24h-2.394v-.92h2.394c.14 0 .24-.1.24-.24v-.88c0-.14-.1-.24-.24-.24h-2.394v-.92h2.394z"/>
  </svg>;

export default Line;