import React from 'react';
import styled from '@emotion/styled';
import { breakpoints, color } from '../../../utils/style';
import { StaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
//import HomePageContext from '../../shared/Context/HomePageContext';

const GameAds = styled('div')`
  text-align: center;
  margin: 30px 0;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
`;

const GameAd = styled('div')`
  width: 23%;
  display: inline-block;
  vertical-align: middle;
  position: relative;
  .game-title {
    width: 100%;
    padding: 10px;
    text-align: center;
    display: none;
    position: absolute;
    top: 1%;
    left: 0;
    z-index: 1;
    color: ${color.white};
  }
  .show-more, .coming-soon {
    width: 120px;
    position: absolute;
    z-index: 1;
    top: 50%;
    left:50%;
    margin: -25px 0 0 -60px;
    display: none;
  }
  .coming-soon {
    background: transparent;
    color: ${color.white};
    padding: 12px;
    text-align: center;
    pointer-events: none;
    width: 150px;
    margin: -25px 0 0 -75px;
  }
  .ribbon {
    position: absolute;
    top: 12%;
    right: -5px;
    z-index: 1;
    padding: 5px 10px;
    background: ${color.orange};
    color: ${color.red};
    font-weight: bold;
    border-radius: 4px;
  }
  &:hover, &:active {
    .ribbon {
      display: none;
    }
    .coming-soon, .show-more, .game-title {
      display: inline-block;
    }
  }
  a, .a {
    display: block;
    &:hover, &:active {
      img {
        filter: brightness(20%);
      }
    }
  }
  .gatsby-image-wrapper {
    border-radius: 10px;
  }
  @media (max-width: ${breakpoints.md}) {
    .coming-soon {
      font-size: 12px;
      margin: -10px 0 0 -57px;
    }
  }
  @media (max-width: ${breakpoints.sm}) {
    width: 47%;
    margin: 1.2%;
    .coming-soon {
      font-size: 0.8rem;
    }
  }
  
`;

const PlayForFreeGame = ( { exclCode } ) => {
  const gameClick = (e, slide_title, game_title, slide_description) => {
    if (slide_description) {
      return false;
    }
  };
  return(
    <StaticQuery
      query=
        {graphql`
        query {
          allWordpressPage(
       filter: {
         slug: {eq: "home"}
       }
      ) {
      edges {
        node {
          acf {
            play_for_free {
              alt_text
              title
              path
              acf {
                slide_url
                slide_title
                slide_description
              }
              localFile {
                childImageSharp {
                  fluid(maxWidth: 300) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
        }
      }
    }
        }
      `}
      render={data => (
        <GameAds>
          {data.allWordpressPage.edges[0].node.acf.play_for_free.slice(0,4).map((item) => {
            const localFile = item.localFile || {};
            if (!item.acf.slide_description && item.acf.slide_url && item.title !== exclCode) {
              return (
                <GameAd key={item.path}>
                  {/*item.acf.slide_title &&
                  <span className="ribbon">{item.acf.slide_title}</span>
                  */}
                  {item.acf.slide_url ?
                    <a href={item.acf.slide_url} title={item.alt_text}
                       onClick={(e)=>gameClick(e, item.title, item.alt_text, item.acf.slide_description)}>
                      {item.acf.slide_title &&
                      <span className="game-title">{item.acf.slide_title}</span>
                      }
                      {!item.acf.slide_description &&
                      <span className="button gradient-button show-more">เล่นเลย</span>
                      }
                      {localFile.childImageSharp && localFile.childImageSharp && item.alt_text &&
                      <Img fluid={localFile.childImageSharp.fluid} alt={item.alt_text}/>
                      }
                    </a> :
                    <div className="a">
                      {localFile.childImageSharp && localFile.childImageSharp && item.alt_text &&
                      <Img fluid={localFile.childImageSharp.fluid} alt={item.alt_text}/>
                      }
                    </div>
                  }
                </GameAd>
              )
            }
          })}
        </GameAds>
      )}
    />
  )};


export default PlayForFreeGame;