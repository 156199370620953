import React from 'react';
const Phone = ({ width = '32', className = '', height = '32', viewBox='0 0 32 32' }) =>
  <svg
    width={width}
    height={height}
    viewBox={viewBox}
    className={className ? className : "svg-icon"}
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <path fill="#ef633e" fillRule="evenodd" d="M24.904 19.292l-2.963-.339a2.322 2.322 0 00-1.913.665l-2.147 2.147a17.553 17.553 0 01-7.688-7.688l2.158-2.159a2.322 2.322 0 00.665-1.913l-.338-2.94A2.335 2.335 0 0010.356 5H8.338C7.019 5 5.923 6.097 6.004 7.415c.619 9.963 8.587 17.92 18.539 18.538 1.318.082 2.415-1.015 2.415-2.333v-2.018a2.312 2.312 0 00-2.054-2.31z"/>
  </svg>;

  export default Phone;