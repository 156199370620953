import React from 'react';
const Transfer = ({ width = '154', className = '', height = '154', viewBox='0 0 154 154' }) =>
  <svg
    width={width}
    height={height}
    viewBox={viewBox}
    className={className ? className : "svg-icon"}
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <defs>
      <path id="6g67ghrv2a" d="M3.43 0H43.1c1.894 0 3.43 1.536 3.43 3.43v58.48c0 1.894-1.536 3.43-3.43 3.43H3.43C1.536 65.34 0 63.804 0 61.91V3.43C0 1.536 1.536 0 3.43 0z"/>
      <path id="x5qaore0cc" d="M3.812 0h39.376C45.294 0 47 1.706 47 3.812v58.376C47 64.294 45.294 66 43.188 66H3.812C1.706 66 0 64.294 0 62.188V3.812C0 1.706 1.706 0 3.812 0z"/>
    </defs>
    <g fill="none" fillRule="evenodd">
      <g>
        <g>
          <g opacity=".2">
            <path d="M0 0L154 0 154 154 0 154z" transform="translate(-883.000000, -327.000000) translate(883.000000, 327.000000)"/>
            <path stroke="#FFF" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" d="M95.333 135H58.667C52.22 135 47 129.232 47 122.111V31.89C47 24.768 52.22 19 58.667 19h36.666C101.78 19 107 24.768 107 31.889v90.222c0 7.121-5.22 12.889-11.667 12.889z" transform="translate(-883.000000, -327.000000) translate(883.000000, 327.000000)"/>
            <path stroke="#FFF" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" d="M76.994 123.5c-.828 0-1.5.672-1.494 1.5 0 .828.672 1.5 1.5 1.5s1.5-.672 1.5-1.5-.672-1.5-1.506-1.5M69 29L85 29" transform="translate(-883.000000, -327.000000) translate(883.000000, 327.000000)"/>
          </g>
          <g transform="translate(-883.000000, -327.000000) translate(883.000000, 327.000000) translate(17.000000, 44.000000)">
            <mask id="5br426vk6b" fill="#fff">
              <use xlinkHref="#6g67ghrv2a"/>
            </mask>
            <use fill="#373737" xlinkHref="#6g67ghrv2a"/>
            <g fill="#717171" mask="url(#5br426vk6b)">
              <g>
                <path d="M25.443 2.966l6.096 9.718c.35.557.181 1.292-.376 1.642-.19.119-.409.182-.633.182H15.578c-.657 0-1.19-.534-1.19-1.191 0-.297.11-.583.31-.802l8.856-9.719c.443-.486 1.196-.52 1.682-.078.08.073.15.156.207.248z" opacity=".4" transform="translate(6.930000, 23.760000)"/>
                <path d="M9.545 1.947l8.678 10.616c.416.51.341 1.26-.168 1.676-.213.174-.479.269-.754.269H2.136c-.658 0-1.191-.534-1.191-1.191 0-.22.06-.434.175-.621L7.607 2.08c.343-.561 1.076-.738 1.637-.395.114.07.216.159.301.262z" transform="translate(6.930000, 23.760000)"/>
              </g>
            </g>
            <path fill="#717171" d="M-5.041 -2.43H89.47200000000001V6.02H-5.041z" mask="url(#5br426vk6b)"/>
            <path fill="#000" d="M4.71 1.794c.837 0 1.515.679 1.515 1.515 0 .836-.678 1.514-1.515 1.514-.836 0-1.514-.678-1.514-1.514s.678-1.515 1.514-1.515zm5.536 0c.836 0 1.514.679 1.514 1.515 0 .836-.678 1.514-1.514 1.514-.837 0-1.515-.678-1.515-1.514s.678-1.515 1.515-1.515zm5.536 0c.836 0 1.514.679 1.514 1.515 0 .836-.678 1.514-1.514 1.514-.837 0-1.515-.678-1.515-1.514s.678-1.515 1.515-1.515z" mask="url(#5br426vk6b)"/>
          </g>
          <path fill="#ef633e" fillRule="nonzero" d="M78.838 91.975c1.194 0 2.162.895 2.162 2 0 1.104-.968 2-2.162 2H67.162c-1.194 0-2.162-.896-2.162-2 0-1.105.968-2 2.162-2zm10.916-11.117v4.233H72.851c-1.193 0-2.16-.948-2.16-2.116 0-1.17.967-2.117 2.16-2.117h16.903zm0-11.429v4.233H58.16c-1.193 0-2.16-.948-2.16-2.116 0-1.17.967-2.117 2.16-2.117h31.594zm0-11.429v4.233H69.826c-1.193 0-2.16-.948-2.16-2.117 0-1.168.967-2.116 2.16-2.116h19.928z" transform="translate(-883.000000, -327.000000) translate(883.000000, 327.000000)"/>
          <g transform="translate(-883.000000, -327.000000) translate(883.000000, 327.000000) translate(90.000000, 44.000000)">
            <mask id="fc9akubwmd" fill="#fff">
              <use xlinkHref="#x5qaore0cc"/>
            </mask>
            <use fill="#373737" xlinkHref="#x5qaore0cc"/>
            <g mask="url(#fc9akubwmd)">
              <g>
                <path fill="#e27e64" d="M25.784 3.13l5.958 9.498c.388.62.201 1.436-.418 1.824-.21.132-.454.202-.703.202H16.008c-.73 0-1.323-.592-1.323-1.323 0-.33.123-.647.345-.891l8.656-9.498c.492-.54 1.329-.58 1.869-.087.088.08.166.173.23.275z" opacity=".4" transform="translate(7.000000, 24.000000)"/>
                <path fill="#ef633e" d="M9.749 2.098l8.498 10.396c.462.566.378 1.399-.187 1.861-.237.194-.532.3-.838.3H2.372c-.731 0-1.323-.593-1.323-1.324 0-.243.067-.482.194-.69L7.596 2.246c.38-.624 1.195-.82 1.818-.44.127.078.24.177.335.292z" transform="translate(7.000000, 24.000000)"/>
              </g>
            </g>
            <path fill="#ef633e" d="M-5.091 -2.455H90.37700000000001V6.08H-5.091z" mask="url(#fc9akubwmd)"/>
            <path fill="#000" d="M4.758 1.813c.845 0 1.53.684 1.53 1.53 0 .844-.685 1.53-1.53 1.53s-1.53-.686-1.53-1.53c0-.846.685-1.53 1.53-1.53zm5.591 0c.845 0 1.53.684 1.53 1.53 0 .844-.685 1.53-1.53 1.53s-1.53-.686-1.53-1.53c0-.846.685-1.53 1.53-1.53zm5.592 0c.845 0 1.53.684 1.53 1.53 0 .844-.685 1.53-1.53 1.53s-1.53-.686-1.53-1.53c0-.846.685-1.53 1.53-1.53z" mask="url(#fc9akubwmd)"/>
          </g>
        </g>
      </g>
    </g>
  </svg>;

export default Transfer;