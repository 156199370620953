import React from 'react';
const Chat = ({ width = '32', className = '', height = '32', viewBox='0 0 32 32' }) =>
  <svg
    width={width}
    height={height}
    viewBox={viewBox}
    className={className ? className : "svg-icon"}
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <path fill="#ef633e" fillRule="evenodd" d="M23.592 4C26.582 4 29 6.49 29 9.57v7.846c0 3.08-2.418 5.57-5.408 5.57h-6.474a2.11 2.11 0 00-1.534.642l-3.952 4.044c-.676.696-1.846.214-1.846-.777v-2.544c0-.776-.598-1.392-1.352-1.392-3.016 0-5.434-2.49-5.434-5.597V9.57C3 6.49 5.418 4 8.408 4h15.184zM8.877 14.986c-.424 0-.786-.15-1.084-.45-.298-.3-.448-.7-.448-1.196s.158-.89.474-1.183a1.62 1.62 0 011.142-.44c.556 0 1.02.235 1.395.703l.736-.84c-.587-.667-1.315-1-2.184-1-.779 0-1.437.265-1.976.794-.538.53-.807 1.19-.807 1.982 0 .791.264 1.447.792 1.966.528.52 1.2.779 2.017.779s1.526-.341 2.127-1.023l-.758-.787c-.37.463-.844.695-1.426.695zm6.192-4.284v2.23H12.96v-2.23h-1.183v5.338h1.183v-2.1h2.109v2.1h1.183v-5.338h-1.183zm6.047 5.338h1.259l-2.29-5.338h-1.146l-2.29 5.338h1.259l.493-1.153h2.222l.493 1.153zm-1.6-3.75l.66 1.55h-1.328l.668-1.55zm5.114-.557h1.502v-1.03h-4.186v1.03h1.501v4.307h1.183v-4.307z"/>
  </svg>;

export default Chat;