import React from 'react';
import Layout from '../../components/shared/layout';
import SEO from '../../components/SEO/SEO';
import styled from '@emotion/styled';
import { breakpoints, ContentStyles, wrapper, color } from '../../utils/style';
import Breadcrumbs from '../../components/shared/Breadcrumbs';
import { graphql } from 'gatsby';
import PlayForFreeGame from '../../components/Content/Lottery/PlayForFreeGame';
//import LotteryGame from '../../components/Content/Lottery/LotteryGame';
import Parallax from '../../components/shared/Backgrounds/Parallax';
import LotteryNoGame from '../../components/Content/Lottery/LotteryNoGame';

const WrapperDesktop = styled('div')`
  ${wrapper}
  h1 {
    font-size: 32px;
    color: ${color.mustard};
  }

  @media (max-width: ${breakpoints.md}) {
    padding: 0 15px;
  }
`;

const ContentWrapper = styled('div')`
  ${wrapper}
  ${ContentStyles}
  @media (max-width: ${breakpoints.md}) {
    margin: 15px auto 0;
    padding: 0 15px;
  }
`;

const Lottery = ({ data, pageContext }) => {
  const currentPage = data.wordpressPage;
  const metaTitle = (currentPage.yoast_meta && currentPage.yoast_meta.yoast_wpseo_title)
    ? currentPage.yoast_meta.yoast_wpseo_title : currentPage.title + "- " + process.env.OWNER;
  const metaDesc = (currentPage.yoast_meta && currentPage.yoast_meta.yoast_wpseo_metadesc)
    ? currentPage.yoast_meta.yoast_wpseo_metadesc : '';
  const breadcrumbs = pageContext.breadcrumbs;
  const page_title = pageContext.page_title;

  return (
    <Layout>
      <SEO
        title={metaTitle}
        description={metaDesc}
        pathname={currentPage.path}
        breadcrumbs={breadcrumbs}
      />
      <Parallax />
      <WrapperDesktop>
        <Breadcrumbs breadcrumbs={breadcrumbs} page_title={page_title} />
        <h1>{currentPage.title}</h1>
        <LotteryNoGame/>
        {/*<LotteryGame h1={currentPage.title}/>*/}
      </WrapperDesktop>
      <ContentWrapper>
        <div className="seo-text-block" dangerouslySetInnerHTML={{ __html: currentPage.content }}/>
      </ContentWrapper>
      <WrapperDesktop>
        <PlayForFreeGame exclCode='none' />
      </WrapperDesktop>
    </Layout>
  );
};

export default Lottery;

export const pageQuery = graphql`
  query($id: String!, $contentImages: [Int]) {
    wordpressPage(id: { eq: $id }) {
      wordpress_id
      title
      content
      path
      yoast_meta {
        yoast_wpseo_title
        yoast_wpseo_metadesc
      }
    }
    placeholder: file(relativePath: { eq: "placeholder.png" }) {
      childImageSharp {
        fluid(maxWidth: 350) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    allWordpressWpMedia(filter: {wordpress_id: {in: $contentImages}}) {
      edges {
        node {
          wordpress_id
          alt_text
          source_url
          localFile {
            publicURL
            childImageSharp {
              fluid(maxWidth: 1400) {
                ...GatsbyImageSharpFluid_withWebp
                presentationWidth
              }
            }
          }
        }
      }
    }
       
  }
`;
