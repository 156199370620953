import React from 'react';
import styled from '@emotion/styled';
import { color } from '../../../utils/style';
import Chat from '../../../icons/svg/lottery/Chat';
import Line from '../../../icons/svg/lottery/Line';
import Phone from '../../../icons/svg/lottery/Phone';

const Wrapper = styled('ul')`
   margin-top: 15px;
   list-style: none;
   li {
     display: flex;
     font-weight: bold;
     font-size: 14px;
     align-items: center;
   }
   svg {
     margin-right: 10px;
   }
   .nobr {
     white-space: nowrap;
   }
   a {
     color: ${color.black};
   }
`;

const ContactBlock = () => {
  const callLink = "tel:" + process.env.PHONE_TO_CALL;
  return (
    <Wrapper>
      <li><Line/> <a href={process.env.LINE_ID_EXT} target="_blank" rel="noopener noreferrer" aria-label="Line">
        {process.env.LINE_ID_TEXT}
      </a> </li>
      <li><Chat/> แชท - แชทสดตลอด 24 ชั่วโมง</li>
      <li>
        <Phone/>
        <div>
          <span>หมายเลขโทรศัพท์ - </span> <a className={"nobr"} href={callLink}>{process.env.PHONE_TO_DISPLAY}</a>
        </div>
      </li>
    </Wrapper>
  );
};

export default ContactBlock;
