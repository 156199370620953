import React from 'react';
import styled from '@emotion/styled';
import { breakpoints, color } from '../../../utils/style';
import ContactBlock from './ContactBlock';
import Transfer from '../../../icons/svg/lottery/Transfer';


const NoGameWrapper = styled('div')`
  margin-bottom: 40px;
  padding: 1px;
  background: ${color.azul};
  background-image: linear-gradient(to left, #57ffeb, #ff989c);
  border-radius: 110px;
  box-shadow: 0 24px 48px 0 rgba(0, 0, 0, 0.05);
  .inner {
    background: ${color.white};
    background-image: linear-gradient(to right, #fff9f8, #effffd), linear-gradient(to left, #57ffeb, #ff989c 2%);
    padding: 40px 160px;
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    border-radius: 110px;
  }
 
  .h2, .steps, .descr {
    flex-basis: 100%;
  }
  .h2 {
    margin: 10px 0 0;
  }
  .descr {
    margin: 25px 0;
  }
  .steps {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
  }
  .step {
    flex-basis: 29%;
  }
  .red {
    color: ${color.red};
  }
  @media (max-width: ${breakpoints.lg}) {
    border-radius: 80px;
    .inner {
      padding: 40px 5%;
      border-radius: 80px;
    } 
  }
  @media (max-width: ${breakpoints.sm}) {
    border-radius: 50px;
    .inner {
      border-radius: 50px;
    }
    .step {
      flex-basis: 100%;
      margin-top: 25px;
    }
  }
`;

const LotteryGame = () => {

  return (
    <NoGameWrapper>
      <div className={"inner"}>
        <div className={"icon-wrap"}>
          <Transfer/>
        </div>
        <h2 className="h2 orange a-center">
          <span className="game-title">สัมผัสประสบการณ์ใหม่กับเว็บไซต์ไฉไลใหม่ล่าสุด</span>
        </h2>
        <p className={"descr a-center"}>ขออภัย เกมนี้ได้ถูกนำออกจากเว็บไซต์นี้แล้ว หากต้องการเล่นเกมนี้ต่อ กรุณาดำเนินการตามขั้นตอนต่อไปนี้:</p>
        <div className={"steps"}>
          <div className={"step"}>
            <p><span className={"red"}>ขั้นตอนที่ 1:</span> กรุณาติดต่อฝ่ายบริการลูกค้าผ่านทาง:</p>
            <ContactBlock/>
          </div>
          <div className={"step"}>
            <p><span className={"red"}>ขั้นตอนที่ 2:</span> แจ้งยูสเซอร์เนมและเบอร์โทรศัพท์ที่ใช้ในการสมัครสมาชิก</p>
          </div>
          <div className={"step"}>
            <p><span className={"red"}>ขั้นตอนที่ 3:</span> กรุณารอสักครู่ พนักงานจะส่งยูสเซอร์เนมและรหัสผ่านพร้อมลิ้งก์เว็บไซต์ที่คุณจะสามารถเล่นเกมต่อได้</p>
          </div>
        </div>
      </div>



    </NoGameWrapper>
  );
};

export default LotteryGame;

